import { HvBreadCrumb, HvDropDownMenu } from '@hitachivantara/uikit-react-core';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Down, Up } from '@hitachivantara/uikit-react-icons';
import './Breadcrumb.css';
import { useSelector } from 'react-redux';

const Breadcrumb = () =>{
	const location = useLocation();
	const navigate = useNavigate();
	const [dropwdownMenu, setDropdownMenu] = useState([]);
	const [breadcrumbData, setBreadcrumbData] = useState([]);
	const [showDropdownMenu, setShowDropdownMenu] = useState(true);
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [showBreadcrumb, setShowBreadcrumb] = useState(true);
	const [qaFactoryDropdown, setQAFactoryDropdown] = useState(false);
	
	const hierarchy = useSelector((state) => state.dashboard.hierarchy);

	useEffect(() => {
		let data = [];
		if(hierarchy.length !== 0 && qaFactoryDropdown){
			let l = eval(hierarchy[0].childThings);
			l.forEach((item) => {
				data.push({
					label: item.name,
					type: item?.thingType,
					thingId: item?.id
				});
			});
			if (location.state?.qaFactory === undefined){
				if(location.pathname === '/qualityanalytics'){
					navigate('/qualityanalytics', {state: {
						...location.state,
						qaFactory: data[0]
					}, replace: true});
				}
				else if(location.pathname === '/tankmaster'){
					navigate('/tankmaster', {state: {
						...location.state,
						qaFactory: data[0]
					}, replace: true});
				}
			}
			setDropdownMenu(data);
		}

	},[hierarchy, qaFactoryDropdown]);

	const getQAFactoryDropdown = () => {
		setBreadcrumbData([{
			label: location.state?.qaFactory?.label || ''
		}]);
		setShowDropdownMenu(true);
		setQAFactoryDropdown(true);
		setShowBreadcrumb(true);
	};

	useEffect(() => {
		if(location.state === null || !location.state.breadcrumb ){
			if(location.pathname === '/tankmaster/finhistory' 
				|| location.pathname === '/tankmaster/finattachhistory' || location.pathname === '/prognostics'){
				setShowBreadcrumb(false);
				setShowDropdownMenu(false);
				return;
			}
			//Setting breadcrumbs from the url
			if(location.pathname === '/qualityanalytics' || location.pathname === '/tankmaster'){
				getQAFactoryDropdown();
				return;
			}
			let path = location.pathname.split('/');
			let data = [];
			path.forEach((item, i) => {
				if(item === 'dashboard'){
					return;
				}
				data.push({
					label: i === 0 ? 'Home' : item.charAt(0).toUpperCase() + item.slice(1),
					url: item
				});
			});
			setBreadcrumbData(data);
			setDropdownMenu([]);
			setShowDropdownMenu(false);
		}
		else {
			if(location.pathname === '/prognostics'){
				setShowBreadcrumb(false);
				setShowDropdownMenu(false);
				return;
			}
			//Setting breadcrumbs from the hierarchy selection 
			setShowBreadcrumb(true);
			setQAFactoryDropdown(false);
			let steps = location.state?.hierarchyDetails?.id?.split('-');
			let data = [];
			let parent = location.state?.breadcrumb[0];
			setShowDropdownMenu(false);
			data.push({
				...parent,
				label: 'Home'
			});
			steps?.forEach((item, index) => {
				if(index === 0){
					return ;
				}
				if(index === steps.length-1){
					setDropdownMenu(parent.data.map((value) => {
						return {
							...value,
							selected: location.state?.hierarchyDetails?.id === value?.id
						};
					}));
					setShowDropdownMenu(true);
				}
				if(parent.data.length > Number(item)-1){
					let x = parent.data[Number(item)-1];
					data.push({
						...x
					});
					parent = parent.data[Number(item)-1];
				}
				else{		
					let x = parent.data.filter((child) => {
						let childId = child.id.split('-');
						return Number(childId.slice(-1)) === Number(item) ? true : false;
					})[0];
					data.push({
						...x
					});
				}
			});
			setBreadcrumbData(data);
		}
		
	},[location]);

	return (
		<>
			{	showBreadcrumb && 
				<div id='breadcrumb' style={{display: 'flex', marginBottom: '10px', stroke:'black'}}>
					<HvBreadCrumb
						aria-label="Breadcrumb"
						id="breadcrumb1"
						onClick={(e, selectedMenu) => {
							navigate(selectedMenu.url.toLowerCase(), {state: {
								...location.state,
								hierarchyDetails: selectedMenu
							}});
						}}
						listRoute={breadcrumbData}
					>
					</HvBreadCrumb>
					{	showDropdownMenu && 
						<HvDropDownMenu 
							dataList={dropwdownMenu}
							icon={isDropdownOpen ? <Up height={'12'} width={'12'}/> : <Down height={'12'} width={'12'}/>} 
							expanded={isDropdownOpen} 
							id={'custom-menu-dropdown'}
							onToggle={(e, open) => setIsDropdownOpen(open)}
							onClick={(e, selectedMenu) => {
								if(selectedMenu.url){
									navigate(selectedMenu.url.toLowerCase(), {state: {
										...location.state,
										hierarchyDetails: selectedMenu
									}});
									setIsDropdownOpen(false);
								}
								else if(location.pathname === '/qualityanalytics'){									
									navigate('/qualityanalytics', {state: {
										...location.state,
										qaFactory: selectedMenu
									}});
									setIsDropdownOpen(false);
								}
								else if(location.pathname === '/tankmaster'){
									navigate('/tankmaster', {state: {
										...location.state,
										qaFactory: selectedMenu
									}});
									setIsDropdownOpen(false);
								}
							}}/>				
					}
				</div>
			}
			
		</>
	);
};

export default Breadcrumb;

